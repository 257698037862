.home{
  display:grid;
  grid-template-columns: repeat(4, 1fr);
  gap:8px;



  //.money{
  //  grid-column-start: 1;
  //  grid-column-end: 3;
  //
  //  @media(max-width: 600px) {
  //    grid-column-start: 1;
  //    grid-column-end: 2;
  //  }
  //}

  @media(max-width: 1000px) {
    grid-template-columns:repeat(2, 1fr);
  }

  @media(max-width: 400px) {
    grid-template-columns:repeat(1, 1fr);
  }
}